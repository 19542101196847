import {
  DISPLAY_NAME_MAX_LENGTH,
  DISPLAY_NAME_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  SUPPORT_GUEST_SERVICE_PHONE
} from './constants';

export const FATAL_LOCAL_STORAGE_REQUIRED =
  "Please enable cookies and local storage in your browser to use this website. You may also see this error when using your browser's incognito or private browsing modes, or if your local storage is full.";
export const ERROR_INVALID_SESSION = 'Your session has expired!';
export const ERROR_NOT_LOGGED_IN = 'Please log in to your account.';
export const ERROR_LOGIN_FAILED = 'Login failed. Please try again.';
export const VALIDATION_ERROR_INVALID_EMAIL =
  'Please enter a valid email address.';
export const VALIDATION_ERROR_INVALID_PASSWORD =
  'Please enter a valid password.';
export const ERROR_EXTERNAL_LOGOUT =
  'You have been logged-out from another window.';
export const ERROR_INVALID_GAME =
  'No game was found matching the provided name. Please check the name, or select another game.';
export const VALIDATION_ERROR_INVALID_UUID = 'Please enter a valid prize code.';
export const RESEND_VERIFICATION_FAILED = `Failed to resend verification email. Please contact us through support chat or call ${SUPPORT_GUEST_SERVICE_PHONE}.`;

export const GLOBAL_RESET_PASSWORD_SUCCESSFUL = 'password_reset_successful';
export const GLOBAL_RESET_PASSWORD_TOKEN_INVALID =
  'password_reset_token_invalid';
export const ERROR_DURING_FORGOT_PASSWORD = 'error_during_forgot_password';
export const GLOBAL_CHANGE_EMAIL_TOKEN_INVALID = 'change_email_token_invalid';

export const GLOBAL_MESSAGES = {
  [GLOBAL_RESET_PASSWORD_SUCCESSFUL]:
    'Your password has been reset. Please log in.',
  [ERROR_DURING_FORGOT_PASSWORD]:
    'Something went wrong. Please try again later.',
  [GLOBAL_RESET_PASSWORD_TOKEN_INVALID]:
    'This password reset link has expired or is invalid. Please reset your password again.',
  [GLOBAL_CHANGE_EMAIL_TOKEN_INVALID]:
    'This email verification link has expired or is invalid. Please submit a new email change.'
};

export const USER_LOCKED_OUT = 'USER_LOCKED_OUT';
export const INCORRECT_PASSWORD = 'INCORRECT_PASSWORD';
export const UNKNOWN_EMAIL = 'UNKNOWN_EMAIL';
export const USER_UNVERIFIED = 'USER_UNVERIFIED';
export const OKTA_FAILURE = 'OKTA_FAILURE';
export const OKTA_REQUIRED = 'OKTA_REQUIRED';

export const SERVER_MESSAGES = {
  [USER_LOCKED_OUT]: `Your account is locked out, please contact our community managers at ${SUPPORT_GUEST_SERVICE_PHONE}`,
  [INCORRECT_PASSWORD]: 'Incorrect password',
  [UNKNOWN_EMAIL]: 'Unknown email',
  [USER_UNVERIFIED]:
    "Your account's email address has not been verified. Please click the verification link in the email that was sent to you.",
  [OKTA_FAILURE]: 'Failed to log in with Okta. Please contact support.',
  [OKTA_REQUIRED]: 'This account must be logged in through Okta.'
};

export const PASSWORD_RULE_DESCRIPTION = `Password must:\n• be at least ${PASSWORD_MIN_LENGTH} characters long\n• be less than ${
  PASSWORD_MAX_LENGTH + 1
} characters\n• contain an uppercase letter\n• contain a lowercase letter\n• contain a number\n• not be a common password`;
export const ERROR_PASSWORDS_DO_NOT_MATCH = 'Passwords do not match!';
export const ERROR_PASSWORD_TOO_SHORT = `Password must be ${PASSWORD_MIN_LENGTH} or more characters.`;
export const ERROR_PASSWORD_TOO_LONG = `Password cannot be more than ${PASSWORD_MAX_LENGTH} characters.`;
export const ERROR_PASSWORD_MUST_CONTAIN_UNIQUE =
  'Password must contain 3 unique characters.';
export const ERROR_PASSWORD_MUST_CONTAIN_LOWERCASE =
  'Password must contain at least one lowercase character.';
export const ERROR_PASSWORD_MUST_CONTAIN_UPPERCASE =
  'Password must contain at least one uppercase character.';
export const ERROR_PASSWORD_MUST_CONTAIN_NUMBER =
  'Password must contain at least one number.';
export const ERROR_NO_OLD_PASSWORD = 'Please enter your previous password.';
export const ERROR_INVALID_PASSWORD = 'The password entered is incorrect.';
export const ERROR_USER_NOT_FOUND = 'User not found!';
export const ERROR_PASSWORD_IS_COMMON = 'Password must not be common.';
export const ERROR_SERVER_ERROR = 'Server Error.';
export const ERROR_INVALID_EMAIL = 'Invalid Email.';
export const ERROR_EMAILS_DO_NOT_MATCH = 'Emails do not match.';
export const DISPLAY_NAME_RULE_DESCRIPTION = `Display Name must:\n• be at least ${DISPLAY_NAME_MIN_LENGTH} characters long\n• be less than ${
  DISPLAY_NAME_MAX_LENGTH + 1
} characters\n• contain only letters, numbers, and spaces`;
export const ERROR_INVALID_DISPLAY_NAME =
  'Display name may only contain letters, numbers, and spaces.';
export const ERROR_DISPLAY_NAME_TOO_SHORT = `Must be at least ${DISPLAY_NAME_MIN_LENGTH} characters.`;
export const ERROR_DISPLAY_NAME_TOO_LONG = `Must be less than ${
  DISPLAY_NAME_MAX_LENGTH + 1
} characters.`;
export const PASSWORD_SUCCESS_MESSAGE = 'Your password has been updated!';
export const PASSWORD_SUCCESS_MESSAGE_MOBILE =
  'Your password has been updated! If you have the app, please open it and login.';
export const SUCCESS_CHANGE_EMAIL_MESSAGE = 'Your email has been updated!';
export const ERROR_REQUIRED = 'Required';
