import React from 'react';
import { retryingLoader } from 'utils/createLoader';
import { themeLoadComplete } from './themeReducer';
import withTheme from './withTheme';

const DEFAULT_THEME = {
  name: 'default',
  loader: () => import(/* webpackChunkName: "theme-default" */ './default'),
  color: '#97131F' // $bethlehem-red
};

let activeTheme = null;
let reduxStore = null;

export const initializeThemeSystem = store => {
  reduxStore = store;
  loadTheme(DEFAULT_THEME);
};

export const getDefaultedThemeColor = () =>
  (activeTheme && activeTheme.color) || DEFAULT_THEME.color;

export const getThemeComponent = componentId => {
  const themedComponent = props => {
    if (
      activeTheme &&
      activeTheme._module &&
      activeTheme._module[componentId]
    ) {
      const Component = activeTheme._module[componentId];
      return <Component {...props} />;
    } else {
      return null;
    }
  };
  return withTheme(themedComponent);
};

const sanitizeTheme = theme => ({ name: theme.name, color: theme.color });

let activeLoadingTheme = null;
function loadTheme(theme) {
  if (!theme._module) {
    if (!theme._loading) {
      activeLoadingTheme = theme;
      theme._loading = retryingLoader(theme.loader)()
        .then(loadedModule => {
          theme._module = loadedModule;
          theme._loading = false;
          if (activeLoadingTheme === theme) {
            setActiveTheme(theme);
          }
        })
        .catch(error => {
          theme._loading = false;
          console.warn('Failed to load theme:', error);
        });
    }
  } else {
    setActiveTheme(theme);
  }
}

function setActiveTheme(theme) {
  if (activeTheme) {
    const oldClass = 'theme-' + activeTheme.name;
    document.documentElement.classList.remove(oldClass);
    document.body.classList.remove(oldClass);
  }
  activeTheme = theme;
  const themeClass = 'theme-' + activeTheme.name;
  document.documentElement.classList.add(themeClass);
  document.body.classList.add(themeClass);
  reduxStore.dispatch(themeLoadComplete(sanitizeTheme(theme)));
}
