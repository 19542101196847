import gql from 'graphql-tag';
import moment from 'moment';

let apolloClient = null;
let lastPage = null;

export const setUserActivityApolloClient = client => {
  apolloClient = client;
};

const SUBMIT_USER_ACTIVITY_PAGE_NAVIGATION = gql`
  mutation MyUserActivityPageNavigation(
    $input: UserActivityPageNavigationInput!
  ) {
    userActivityPageNavigation(input: $input)
  }
`;

export const userActivityMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = action.payload.pathname;
    const localTime = moment().toISOString(true);
    const userAgent = navigator.userAgent;

    if (nextPage !== lastPage) {
      lastPage = nextPage;
      apolloClient.mutate({
        mutation: SUBMIT_USER_ACTIVITY_PAGE_NAVIGATION,
        variables: {
          input: { page: nextPage, localTime: localTime, userAgent: userAgent }
        }
      });
    }
  }

  return next(action);
};
