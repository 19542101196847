import { fromJS } from 'immutable';
import { UPDATE_CURRENT_MESSAGE } from './actions';

const initialState = fromJS({ currentId: 0, previousId: 0 });

export default function communityMessageReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_MESSAGE:
      return state
        .set('previousId', state.get('currentId'))
        .set('currentId', action.payload.id);

    default:
      return state;
  }
}
