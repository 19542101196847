// Import polyfills. Must be imported before all other dependencies.
import 'react-app-polyfill/stable';

import React from 'react';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { initializeThemeSystem } from 'theme';
import Admin from 'containers/Admin/AdminLoadable';
import AdminEmployeeApp from 'containers/AdminEmployee/AdminEmployeeApp';
import AdminMobileLoadable from 'containers/AdminMobile/AdminMobileLoadable';
import App from 'containers/App';
import ErrorBoundary from 'containers/ErrorBoundary';
import MaintenanceMode from 'containers/MaintenanceMode';
import TimeSync from 'utils/TimeSync';
import { registerLogoutHandlers } from 'utils/auth';
import { isLocalStorageAvailable } from 'utils/localStorage';
import { FATAL_LOCAL_STORAGE_REQUIRED } from 'utils/messages';
import { initializeMode, isEmployeeWeb } from 'utils/mode';
import { configureZendesk } from 'utils/zendesk';
import configureApollo from './configureApollo';
import configureLinkify from './configureLinkify';
import configureStore from './configureStore';
import createSoundRegistry from './createSoundRegistry';
import { initializeGoogleTagManager } from './googleTagManagerMiddleware';
import { setUserActivityApolloClient } from './userActivityMiddleware';
// Import global styles.
import './index.css';

if (!isLocalStorageAvailable()) {
  // Show an error if localstorage is disabled
  const container = document.createElement('div');
  container.className = 'fatal-error';
  const text = document.createTextNode(FATAL_LOCAL_STORAGE_REQUIRED);
  container.appendChild(text);
  document.body.appendChild(container);
  throw new Error('Local storage access is required');
}

// Check for mobile app key
initializeMode();
// Register zendesk chat widget.
configureZendesk(document, window);

// Create redux store with history and sound data.
const initialState = {};
export const history = createBrowserHistory();
const sounds = createSoundRegistry();
const store = configureStore(initialState, history, sounds);
const MOUNT_NODE = document.getElementById('root');

// Create Apollo client.
const apolloClient = configureApollo(history);

setUserActivityApolloClient(apolloClient);
initializeGoogleTagManager(apolloClient);

// Register handlers and event listeners for logging out.
registerLogoutHandlers(apolloClient, history);

configureLinkify();

// Register time synchronization
TimeSync.startSynchronization(store);

initializeThemeSystem(store);

const render = () => {
  ReactDOM.render(
    process.env.NODE_ENV === 'production' ? (
      <ErrorBoundary>{app()}</ErrorBoundary>
    ) : (
      app()
    ),
    MOUNT_NODE
  );
};

const routes = isEmployeeWeb()
  ? () => <AdminEmployeeApp />
  : () => (
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/admin-mobile" component={AdminMobileLoadable} />
        <Route component={App} />
      </Switch>
    );

function app() {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ConnectedRouter history={history}>
          <MaintenanceMode>{routes()}</MaintenanceMode>
        </ConnectedRouter>
      </ApolloProvider>
    </Provider>
  );
}

if (module.hot) {
  // Hot reloadable React components files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(
    [
      './containers/App',
      './containers/Admin',
      './containers/AdminMobile',
      './containers/AdminEmployee/AdminEmployeeApp'
    ],
    () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render();
    }
  );
}

render();
