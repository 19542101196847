import {
  getLocalData,
  removeLocalData,
  setLocalData
} from 'utils/localStorage';

const getLocalStorageCasinoverse = () => !!getLocalData('casinoverse', false);
const setLocalStorageCasinoverse = s => setLocalData('casinoverse', !!s);
const clearLocalStorageCasinoverse = () => removeLocalData('casinoverse');

// The casinoverse setting is fixed when the page is loaded. To change it, a reload is required.
const isCasinoverse = getLocalStorageCasinoverse();
export const getCasinoverse = () => isCasinoverse;

let reloadTriggered = false;
export const setCasinoverse = casinoverse => {
  if (!reloadTriggered && casinoverse !== isCasinoverse) {
    reloadTriggered = true;
    if (!casinoverse) {
      clearLocalStorageCasinoverse();
    } else {
      setLocalStorageCasinoverse(true);
    }
    window.location.reload();
  }
};

export const toggleCasinoverse = () => {
  setCasinoverse(!isCasinoverse);
};
