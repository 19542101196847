import React from 'react';

export function getUserAdminLink(user) {
  return user && `/admin/users/search/${user.userId}/profile`;
}

export function isSuperAdmin(user) {
  return user && user.roles && user.roles.includes('super-admin');
}

export function isCommunityManager(user) {
  return user && user.roles && user.roles.includes('community-manager');
}

export function isHostEmployee(user) {
  return user && user.roles && user.roles.includes('host-employee');
}

export function isHotelBookingAdmin(user) {
  return user && user.roles && user.roles.includes('hotel-booking-admin');
}

export function isHotelWaitlistApprover(user) {
  return user && user.roles && user.roles.includes('hotel-waitlist-approver');
}

export function isCommunityEventsAdmin(user) {
  return user && user.roles && user.roles.includes('community-events-admin');
}

export function isWccSupportUser(user) {
  return user && user.roles && user.roles.includes('wcc-support');
}

export function isWccDevSupport(user) {
  return user && user.roles && user.roles.includes('wcc-dev-support');
}

export function isWccSupervisorUser(user) {
  return user && user.roles && user.roles.includes('wcc-supervisor');
}

export function isPrizeReportsAdmin(user) {
  return user && user.roles && user.roles.includes('prize-reports');
}

export function isGameFinderAdmin(user) {
  return user && user.roles && user.roles.includes('game-finder-admin');
}

export function isFrontLineEmployee(user) {
  return user && user.roles && user.roles.includes('front-line-employee');
}

export function isGamificationSkipper(user) {
  return user && user.roles && user.roles.includes('skip-user-gamification');
}

export function isHostEmployeeManager(user) {
  return user && user.roles && user.roles.includes('host-employee-manager');
}

export function isEmpAppAccessUser(user) {
  return user && user.roles && user.roles.includes('emp-app-access');
}

export function isEmpAppOffPropertyAccessUser(user) {
  return (
    user && user.roles && user.roles.includes('emp-app-off-property-access')
  );
}

export function isEmpAppFullGuestInfoAccessUser(user) {
  return (
    user && user.roles && user.roles.includes('emp-app-full-guest-info-access')
  );
}

export function isEmpAppHostBookingAccessUser(user) {
  return (
    user && user.roles && user.roles.includes('emp-app-host-booking-access')
  );
}

export function isEmpAppContactGuestAccessUser(user) {
  return (
    user && user.roles && user.roles.includes('emp-app-contact-guest-access')
  );
}

export function isEmpAppNoGamingLicensedAccessUser(user) {
  return (
    user &&
    user.roles &&
    user.roles.includes('emp-app-no-gaming-licensed-access')
  );
}

export function isWccRoleManagerUser(user) {
  return isWccSupervisorUser(user) || isSuperAdmin(user);
}

export function isWinnersListAdmin(user) {
  return user && user.roles && user.roles.includes('winners-list-admin');
}

export function isCvDebugAccessUser(user) {
  return user && user.roles && user.roles.includes('cv-debug-access');
}

export function isMiniGameAdminUser(user) {
  return user && user.roles && user.roles.includes('mini-game-admin');
}

export function isMobileAdmin(user) {
  return (
    isSuperAdmin(user) ||
    isCommunityManager(user) ||
    isHostEmployee(user) ||
    isHotelBookingAdmin(user) ||
    isHotelWaitlistApprover(user)
  );
}

export function isMobileHostUser(user) {
  return isHotelBookingHost(user) || isFrontLineEmployee(user);
}

export function isHotelBookingHost(user) {
  return (
    isHostEmployee(user) ||
    isHotelBookingAdmin(user) ||
    isHotelWaitlistApprover(user) ||
    isEmpAppUser(user)
  );
}

export function isCompBalanceAccess(user) {
  return (
    isHostEmployee(user) ||
    isHotelBookingAdmin(user) ||
    isHotelWaitlistApprover(user) ||
    isEmpAppFullGuestInfoAccessUser(user)
  );
}

export function isEmpAppUser(user) {
  return (
    isEmpAppAccessUser(user) ||
    isEmpAppOffPropertyAccessUser(user) ||
    isEmpAppFullGuestInfoAccessUser(user) ||
    isEmpAppHostBookingAccessUser(user) ||
    isEmpAppContactGuestAccessUser(user) ||
    isEmpAppNoGamingLicensedAccessUser(user)
  );
}

export function isOnlyEmpAppNoGamingLicensedAccessUser(user) {
  return (
    isEmpAppNoGamingLicensedAccessUser(user) &&
    true !==
      (isEmpAppFullGuestInfoAccessUser(user) ||
        isEmpAppHostBookingAccessUser(user) ||
        isEmpAppContactGuestAccessUser(user) ||
        isHostEmployee(user) ||
        isHotelBookingAdmin(user) ||
        isHotelWaitlistApprover(user))
  );
}

export function isAdmin(user) {
  return (
    isSuperAdmin(user) ||
    isCommunityManager(user) ||
    isHotelBookingAdmin(user) ||
    isCommunityEventsAdmin(user) ||
    isWccSupportUser(user) ||
    isPrizeReportsAdmin(user) ||
    isGameFinderAdmin(user) ||
    isWccSupervisorUser(user) ||
    isHostEmployeeManager(user) ||
    isWinnersListAdmin(user) ||
    isWccDevSupport(user)
  );
}

export function isWccAdmin(user) {
  return (
    isSuperAdmin(user) ||
    isCommunityManager(user) ||
    isCommunityEventsAdmin(user) ||
    isWccSupportUser(user)
  );
}

export function hasPatronAccount(user) {
  return (
    user &&
    user.roles &&
    user.roles.includes('user-session-with-patron-account')
  );
}

export function hasRmgAccount(user) {
  return user && user.roles && user.roles.includes('user-with-rmg-account');
}

export function isPublicCm(user) {
  return isCommunityManager(user) && !isWccSupportUser(user);
}

export function cmDisplayName(user) {
  if (isPublicCm(user)) {
    return <span className="cm-display-name">{user.displayName}</span>;
  } else {
    return user.displayName;
  }
}
