import gql from 'graphql-tag';

const userIdAndDisplay = gql`
  fragment UserIdAndDisplayFields on User {
    userId
    displayName
    avatarUrl
    roles
  }
`;

const user = gql`
  fragment UserFields on User {
    email
    userId
    displayName
    preferredLocation
    avatarUrl
    creationDate
    roles
  }
`;

// TODO: once Posts and Comments share an interface for page fields, combine these.
const postPagination = gql`
  fragment PostPaginationFields on Posts {
    startId
    endId
    hasAnyNext
  }
`;

const commentPagination = gql`
  fragment CommentPaginationFields on Comments {
    startId
    endId
    hasAnyNext
  }
`;

const post = gql`
  fragment PostFields on Post {
    id
    message
    device
    deviceInfo
    created
    lastEditedAt
    commentCount
    isPinned
    isLocked
    isSystemPost
    likeCount
    likedByMe
    byMe
    imageUrl
    imageAltText
    likedBy(posts: $posts) {
      ...UserIdAndDisplayFields
    }
    author {
      ...UserIdAndDisplayFields
    }
  }
  ${userIdAndDisplay}
`;

const comment = gql`
  fragment CommentFields on Comment {
    id
    postId
    comment
    created
    lastEditedAt
    likedByMe
    likeCount
    byMe
    likedBy(comments: $comments) {
      ...UserIdAndDisplayFields
    }
    author {
      ...UserIdAndDisplayFields
    }
  }
  ${userIdAndDisplay}
`;

const postWithComments = gql`
  fragment PostWithComments on Post {
    ...PostFields
    comments(comments: $comments) {
      comments {
        ...CommentFields
      }
      ...CommentPaginationFields
    }
  }
  ${post}
  ${comment}
  ${commentPagination}
`;

const chatMessage = gql`
  fragment ChatMessageFields on ChatMessage {
    id
    room
    message
    created
    author {
      ...UserIdAndDisplayFields
    }
  }
  ${userIdAndDisplay}
`;

const communityMessage = gql`
  fragment CommunityMessageFields on CommunityMessage {
    id
    message
    start
    end
    durationSec
  }
`;

const game = gql`
  fragment Game on Game {
    id
    name
    displayName
    slug
    enabled
    isCasinoverse
    badge
    gameDesktopCover
    gameIcon
  }
`;

const tournament = gql`
  fragment Tournament on Tournament {
    id
    name
    startTime
    endTime
    status
    description
    game {
      ...Game
    }
    winningPlaces {
      winningPlaces {
        place
      }
    }
    serverTime
    userInGroup
  }
  ${game}
`;

const tournamentLeaderboard = gql`
  fragment TournamentLeaderboard on Tournament {
    leaderBoard {
      rankings {
        player {
          displayName
          userId
          avatarUrl
        }
        score
        rank
      }
    }
  }
`;

const gameRankings = gql`
  fragment GameRankingsFields on GameRankings {
    leaderboard {
      rank
      score
      player {
        ...UserIdAndDisplayFields
      }
    }
    selfRanking {
      rank
      score
    }
  }
  ${userIdAndDisplay}
`;

const liveStreamData = gql`
  fragment LiveStreamDataFields on LiveStreamData {
    enabled
    title
    embedUrl
  }
`;

const fragments = {
  user,
  userIdAndDisplay,
  postPagination,
  commentPagination,
  post,
  postWithComments,
  comment,
  chatMessage,
  communityMessage,
  game,
  tournament,
  tournamentLeaderboard,
  gameRankings,
  liveStreamData
};

export default fragments;
