/**
 * Asynchronously loads the component
 */
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Link, Redirect } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { isAuthenticated } from 'utils/auth';
import { getUserData } from 'utils/graphQLFunctions';
import { isEmbedded } from 'utils/mode';
import { isEmpAppUser, isMobileHostUser } from 'utils/roles';

const Loader = Loadable({
  loader: () => import(/* webpackChunkName: "admin-mobile" */ './index'),
  loading: LoadingComponent
});

class AdminMobileLoadable extends Component {
  constructor(props) {
    super(props);
    this.isAuth = isAuthenticated();
  }

  render() {
    const { user, loading } = this.props.data;
    if (!this.isAuth) {
      return <Redirect to="/" />;
    } else if (loading) {
      return null;
    } else if (!user || !(isMobileHostUser(user) || isEmpAppUser(user))) {
      return (
        <>
          <div>You do not have permission to view this page.</div>
          {!isEmbedded() && <Link to="/logout">Logout</Link>}
        </>
      );
    } else {
      return <Loader />;
    }
  }
}

function LoadingComponent({ error, pastDelay }) {
  if (error) {
    return (
      <div>
        Failed to load component due to Error:
        {error.message || JSON.stringify(error)}
      </div>
    );
  } else if (pastDelay) {
    return <LoadingIndicator expand />;
  } else {
    return null;
  }
}

export default getUserData()(AdminMobileLoadable);
