/**
 * Asynchronously loads the component
 */
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Redirect } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { isAuthenticated } from 'utils/auth';
import { getUserData } from 'utils/graphQLFunctions';
import { isAdmin } from 'utils/roles';

const Loader = Loadable({
  loader: () => import(/* webpackChunkName: "admin" */ './index'),
  loading: LoadingComponent
});

class AdminLoadable extends Component {
  constructor(props) {
    super(props);
    this.isAuth = isAuthenticated();
  }

  render() {
    const { user, loading } = this.props.data;
    if (!this.isAuth) {
      return <Redirect to="/" />;
    } else if (loading) {
      return null;
    } else if (!user || !isAdmin(user)) {
      return <Redirect to="/" />;
    } else {
      return <Loader />;
    }
  }
}

function LoadingComponent({ error, pastDelay }) {
  if (error) {
    return (
      <div>
        Failed to load component due to Error:
        {error.message || JSON.stringify(error)}
      </div>
    );
  } else if (pastDelay) {
    return <LoadingIndicator expand />;
  } else {
    return null;
  }
}

export default getUserData()(AdminLoadable);
