import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminEmployeeLogin from 'containers/AdminEmployee/AdminEmployeeLogin/Loadable';
import AdminEmployeeLogout from 'containers/AdminEmployee/AdminEmployeeLogout/Loadable';
import AdminMobileLoadable from 'containers/AdminMobile/AdminMobileLoadable';

function AdminEmployeeApp() {
  return (
    <Switch>
      <Route path="/admin-mobile" component={AdminMobileLoadable} />
      <Route path="/logout" component={AdminEmployeeLogout} />
      <Route component={AdminEmployeeLogin} />
    </Switch>
  );
}

export default AdminEmployeeApp;
