// String.replaceAll support isn't common until mid-2020.
// A simplified polyfill just for replacing strings. Does not support regex.
export function replaceAllStrings(string, target, replacement) {
  if (typeof string !== 'string' || typeof target !== 'string') {
    return string;
  } else if (string.replaceAll) {
    return string.replaceAll(target, replacement);
  } else {
    return string.split(target).join(replacement);
  }
}

export function collapseWhitespace(string) {
  return string && string.replace(/[ \t\n\r]+/g, ' ').trim();
}

export function sortStringFieldAsInt(a, b, desc) {
  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  // force any string values to lowercase
  a = typeof a === 'string' ? parseInt(a, 10) : a;
  b = typeof b === 'string' ? parseInt(b, 10) : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
}

export function truncateText(text, lengthVal) {
  let addEllipses = '';
  if (text.length > lengthVal) addEllipses = '...';
  return text.substring(0, lengthVal).trim() + addEllipses;
}

export function isNumeral(value) {
  return /^-?\d+$/.test(value);
}
